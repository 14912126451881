import React from "react"
import { graphql } from "gatsby"
import { ShopDirectory as PageQuery, Link, Meta, ShopDirectorySEO, Menu, SiteSettings, SiteBanner } from 'src/queries'
import Layout from 'src/components/layout'
import Slices from 'src/components/slices'
import Instagram from 'src/components/instagram'
import {getPageData} from 'src/utilities/data'
import SEO from 'src/components/seo'

const ShopDirectory = getPageData(({pageData, menuData, siteBannerData, siteSettings, pageContext}) =>
  <Layout pageContext={pageContext} menuData={menuData} siteBannerData={siteBannerData} siteSettings={siteSettings}>
    <SEO {...pageData} lang={pageContext.lang} />
    <Slices data={pageData.body} pageData={pageData} />
    <Instagram menuData={menuData} />
  </Layout>
)

ShopDirectory.fragments = [PageQuery, Link, Meta, ShopDirectorySEO, Menu, SiteSettings, SiteBanner]
export default ShopDirectory
export const query = graphql`
  query ($lang: String) {
    prismic {
      allShop_directors(lang: $lang) { ...ShopDirectory }
      allMenus(lang: $lang) { ...Menu }
      allSite_settingss(lang: $lang) { ...SiteSettings }
      allSite_banners(lang: $lang) { ...SiteBanner }
    }
  }
`