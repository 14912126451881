import React, {Component} from "react"
import styles from 'src/css/instagram.module.css'
import PageContext from 'src/components/page_context'
import {alias} from 'src/utilities/locale'

const INSTA_BANNER_IMAGE_COUNT = 8

class Instagram extends Component {
  state = {instagramImageURLs: []}

  async componentDidMount(){
    if (this.boardId) {
      const instagramImageURLs = this.sortFromCenter(
        new Array(INSTA_BANNER_IMAGE_COUNT).fill(0).map((_,id) => `https://likeshop.me/board/${this.boardId}/newest/thumbs/${id+1}.jpeg`)
      )
      this.setState({instagramImageURLs})
    }
  }

  get boardId(){
    // References to Gatsby env variables must be static, so we cannot make this dynamic unfortunately
    return {
      au:process.env.GATSBY_LIKESHOP_BOARD_ID_AU,
      nz:process.env.GATSBY_LIKESHOP_BOARD_ID_NZ,
      uk:process.env.GATSBY_LIKESHOP_BOARD_ID_UK,
      us:process.env.GATSBY_LIKESHOP_BOARD_ID_US
    }[alias(this.props.pageContext.lang)]
  }

  renderInstagramImage = (url, idx) =>
    <a key={idx} href={`https://www.instagram.com/laybuy.${alias(this.props.pageContext.lang)}/`} rel="noreferrer noopener" target="_blank">
      <img src={url} alt='' loading="lazy" />
    </a>

  sortFromCenter = collection =>
    collection.reduce((agg, elm, idx) => idx % 2 === 1 ? [...agg, elm] : [elm, ...agg], [])

  render = () => {
    if (!this.boardId) {
      return false
    }
    return (
      <div className={styles.instagramWrapper}>
        <div className={styles.instagramGallery}>
          {this.state.instagramImageURLs.map(this.renderInstagramImage)}
        </div>
      </div>
    )
  }
}

export default (props) =>
  <PageContext.Consumer>
    {pageContext => <Instagram pageContext={pageContext} {...props}/>}
  </PageContext.Consumer>
